var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "px-3 pt-3 mb-7", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "secondary white--text text-h5" } },
                    [_vm._v(" Assign Task ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "pa-3" },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-5",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Company",
                          disabled: "",
                        },
                        model: {
                          value: _vm.companyName,
                          callback: function ($$v) {
                            _vm.companyName = $$v
                          },
                          expression: "companyName",
                        },
                      }),
                      _c(
                        "v-form",
                        [
                          _c("v-select", {
                            staticClass: "mt-5",
                            attrs: {
                              items: _vm.assignTaskOption,
                              label: "Assign Task to...",
                              dense: "",
                              outlined: "",
                            },
                            on: { change: _vm.checkAssignTask },
                            model: {
                              value: _vm.assignTask,
                              callback: function ($$v) {
                                _vm.assignTask = $$v
                              },
                              expression: "assignTask",
                            },
                          }),
                          _vm.isAssignRole
                            ? _c("v-autocomplete", {
                                staticClass: "mt-5",
                                attrs: {
                                  label: "Department...",
                                  dense: "",
                                  outlined: "",
                                  rules: _vm.rules,
                                  "item-text": "name",
                                  "return-object": "",
                                  items: _vm.AssignRoleOption,
                                },
                                model: {
                                  value: _vm.form.role,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "role", $$v)
                                  },
                                  expression: "form.role",
                                },
                              })
                            : _vm._e(),
                          _vm.isAssignPIC
                            ? _c("v-autocomplete", {
                                ref: "pic",
                                staticClass: "mt-5",
                                attrs: {
                                  rules: _vm.rules,
                                  "item-text": "staff_name",
                                  "return-object": "",
                                  items: _vm.picOption,
                                  solid: "",
                                  outlined: "",
                                  "rv-model": "form.pic",
                                  label: "PIC...",
                                  "menu-props": { bottom: true, offsetY: true },
                                },
                                model: {
                                  value: _vm.form.pic,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "pic", $$v)
                                  },
                                  expression: "form.pic",
                                },
                              })
                            : _vm._e(),
                          _c("v-autocomplete", {
                            ref: "priority",
                            attrs: {
                              rules: _vm.rules,
                              dense: "",
                              outlined: "",
                              label: "Priority",
                              items: _vm.priorityOption,
                            },
                            model: {
                              value: _vm.form.priority,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "priority", $$v)
                              },
                              expression: "form.priority",
                            },
                          }),
                          _c("v-autocomplete", {
                            ref: "category",
                            attrs: {
                              rules: _vm.rules,
                              dense: "",
                              outlined: "",
                              label: "Category",
                              items: _vm.categoryOption,
                            },
                            on: { change: _vm.checkCategoryOptions },
                            model: {
                              value: _vm.form.category,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "category", $$v)
                              },
                              expression: "form.category",
                            },
                          }),
                          this.categoryIsOthers
                            ? _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: "Category",
                                },
                                model: {
                                  value: _vm.form.otherCategory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "otherCategory", $$v)
                                  },
                                  expression: "form.otherCategory",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-dialog",
                            {
                              attrs: { width: "auto", height: "auto" },
                              on: { "click:outside": _vm.resetDeadline },
                              model: {
                                value: _vm.isDeadline,
                                callback: function ($$v) {
                                  _vm.isDeadline = $$v
                                },
                                expression: "isDeadline",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-center" },
                                [
                                  _c("v-card", { staticClass: "pa-3" }, [
                                    _c("p", [_vm._v(" Task deadline : ")]),
                                    _c(
                                      "div",
                                      [
                                        _c("v-date-picker", {
                                          attrs: {
                                            landscape: true,
                                            reactive: true,
                                          },
                                          model: {
                                            value: _vm.form.deadline,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "deadline",
                                                $$v
                                              )
                                            },
                                            expression: "form.deadline",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { attrs: { name: "footer" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-end my-3",
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                plain: "",
                                              },
                                              on: { click: _vm.resetDeadline },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: _vm.confirmDeadline,
                                              },
                                            },
                                            [_vm._v(" Confirm ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("v-textarea", {
                            attrs: {
                              filled: "",
                              dense: "",
                              placeholder: "Description...",
                              solo: "",
                              counter: 500,
                              rules: _vm.rulesMaxLength,
                            },
                            model: {
                              value: _vm.form.action,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "action", $$v)
                              },
                              expression: "form.action",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end mt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", plain: "" },
                                  on: { click: _vm.redirectBack },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.api.isLoading,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }